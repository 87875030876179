<template>
  <div class="room_info" @click.prevent.stop="joinGame">
    <div class="area_left">
      <div class="flex-grow-1">
        <div>
          <div class="user_num" :class="{gold: isPlayerCntSix(), green: !isPlayerCntSix()}">
            <span class="material-symbols-rounded" :class="{'me-1': !isPlayerCntSix()}">{{isPlayerCntSix() ? "person" : "groups"}}</span>
            <span><span :class="{join: count > 0}">{{ count }}</span>/{{ limit }}</span>
          </div>
          <div class="tit">{{ title }}</div>
        </div>
        <div class="blind_point"><span class="txt_blind">블라인드</span>{{ smallBlind | formatCash }} / {{ bigBlind | formatCash }}</div>
      </div>
      <div class="buyin">
        바이인
        <span class="yellow">{{ buyin | formatCash }}</span>
        <span> - </span>
        <span v-if="maxBuyin === '*'" class="yellow">제한없음</span>
        <span v-else class="yellow">
          {{ maxBuyin | formatCash }}
        </span>
      </div>
    </div>
    <div class="area_right">
      <img src="@/assets/v2/svg/icon_arrow_forward.svg" alt="입장">
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {getRouteNameByGameType} from "../gameCommon";

export default {
  name: "RoomListItem",
  props: {
    roomNumber: {
      type: [Number, String],
      required: true,
    },
    roomUri: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: [Number, String],
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    buyin: {
      type: Number,
      required: true,
    },
    maxBuyin: {
      type: [String, Number],
      required: true,
    },
    smallBlind: {
      type: Number,
      required: true,
    },
    bigBlind: {
      type: Number,
      required: true,
    },
    playerCnt: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getRoomUrl(){
      const path = getRouteNameByGameType(this.type);
      return `${this.roomUri}/${path}`;
    },
    isPlayerCntSix(){
      return this.playerCnt === 6;
    },
    joinGame() {
      this.$emit('join', {
        token: this.token,
        roomCode: this.roomNumber,
        data: {
          roomNumber: this.roomNumber,
          roomUrl: this.getRoomUrl(),
          title: this.title,
          type: this.type,
          limit: this.limit,
          count: this.count,
          buyin: this.buyin,
          maxBuyin: this.maxBuyin,
          smallBlind: this.smallBlind,
          bigBlind: this.bigBlind,
        }
      });
    }
  },
  computed: {
    ...mapGetters(['token']),
  }
}
</script>